<template>
  <div class="department">
    <el-button @click="handleOpenDia('add')" type="primary" size="mini" class="mb20">新增部门</el-button>
    <el-table
      :data="tableData"
      size="small"
      border
      :cell-style="$style.cellStyle"
      :header-cell-style="$style.rowClass"
    >
      <el-table-column prop="name" label="部门名称"></el-table-column>
      <el-table-column prop="description" label="职能描述"></el-table-column>
      <!-- <el-table-column prop="sum" label="成员数量" align="center">
        <template v-slot="{ row }">
          <span :style="themeStyle">{{ row.sum }}</span>
        </template>
      </el-table-column>-->
      <el-table-column prop="createTime" label="添加时间"></el-table-column>

      <el-table-column label="操作" align="center" width="200px">
        <template v-slot="{ row }">
          <el-button type="text" size="mini" @click="handleOpenDia( row )">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="page tc mt10"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.limit"
      @pagination="getList"
    />

    <el-dialog
      :title="title"
      width="30%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="departmentForm" label-width="90px" ref="departmentForm" :rules="rules">
        <el-form-item label="部门名称:" prop="name">
          <el-input v-model="departmentForm.name"></el-input>
        </el-form-item>
        <el-form-item label="职能描述:" prop="description">
          <el-input type="textarea" v-model="departmentForm.description" rows="10" resize="none"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from "@/components/Pagination";
import {
  readDepartmentList,
  createDepartmentResource,
  updateDepartmentResource
} from "@/api/permission";
const ADD_TITLE = "新增部门";
const EDIT_TITLE = "编辑部门";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Rules {
  name = [{ required: true, message: "请填写部门名称", trigger: "blur" }];
  description = [
    { required: true, message: "请填写部门描述", trigger: "blur" }
  ];
}
export default {
  name: "Department",
  components: {
    pagination
  },
  data() {
    return {
      themeStyle: {
        color: "#33bea7"
      },
      page: new Page(), // 分页
      rules: new Rules(),
      title: ADD_TITLE,
      dialogVisible: false,
      tableData: [],
      departmentForm: {
        name: "",
        description: ""
      },
      cannotCompile: false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      readDepartmentList({ ...this.page }).then(res => {
        this.tableData = res.data.list;
        this.page.total = res.data.dataCount;
      });
    },
    // 编辑
    handleOpenDia(row) {
      this.cannotCompile = false;
      if (row == "add") {
        this.title = ADD_TITLE;
      } else {
        this.title = EDIT_TITLE;
        this.departmentForm = Object.assign({}, row);
        if (
          this.departmentForm.name == "客服部" ||
          this.departmentForm.name == "运营部"
        ) {
          this.cannotCompile = true;
        }
      }
      this.dialogVisible = true;
    },
    // 提交
    handleSubmit() {
      this.$refs.departmentForm.validate(valid => {
        if (valid) {
          if (this.title == EDIT_TITLE) {
            this.hint("更新此部门信息");
          } else {
            this.hint("添加此部门信息");
          }
        }
      });
    },
    // 提示
    hint(text) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 确定提交
    confirm() {
      if (this.title == EDIT_TITLE) {
        updateDepartmentResource({ ...this.departmentForm }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.dialogVisible = false;
          this.getList();
        });
      } else {
        createDepartmentResource({ ...this.departmentForm }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.dialogVisible = false;
          this.getList();
        });
      }
    }
  }
};
</script>

<style lang='scss' scoped>
</style>
